<template>
  <tbody>
    <tr v-if="fields.is_need_filter">
      <td v-for="[fieldName, field] of Object.entries(payloadFields)">
        <v-text-field
          v-model="filterModel[fieldName]"
          :label="`${field.label} - фильтровать`"
          density="compact"
          hide-details
          variant="outlined"
          color="primary"
        ></v-text-field>
      </td>
    </tr>
    <tr
      v-for="(item, i) of paginatedItems"
      :key="i"
      style="height: 35px"
    >
      <td v-if="item.id >= 0 || isEqualToChapterValues(item)">
        <div
          v-if="chaptersLoaded"
          class="chapters_item pt-2"
        >
          <div>
            <div
              v-for="(field, j) of Object.entries(payloadFields)"
              :key="j"
              style="min-width: 100px"
              class="mb-2"
            >
              <slot
                :name="`col.${field[0]}`"
                :update="onUpdate"
                :field="field"
                :item="item"
                :idx="i"
              >
                <editable-field
                  :key="`${field[0]}:${i}:${j}`"
                  v-model="item[field[0]]"
                  :field-name="field[0]"
                  :fields="fields"
                  :field="fields[fieldName]"
                  :loading="loading"
                  :show-data-raw="item['rawData'] ? item['rawData'] : getRawItem(i)?.[field[0]]?.value ?? getRawItem(i)?.[field[0]]?.values ?? getRawItem(i)?.[field[0]]"
                  @update:modelValue="onUpdate({field, item})"
                />
              </slot>
            </div>
          </div>
          <div>
            <v-btn
              v-if="!readOnly && !item.canDelete && !deleteRule(item)"
              size="extra-small"
              class="text-caption px-2"
              color="error"
              @click="del(item._index)"
            >
              X
            </v-btn>
          </div>
        </div>
      </td>
    </tr>
    <v-skeleton-loader
      v-show="loading"
      type="table-row@3"
    ></v-skeleton-loader>
  </tbody>
  <v-pagination
    v-if="!loading && pagesTotal > 1 && fields.is_need_pagination"
    v-model="page"
    :length="pagesTotal"
    color="primary"
    density="compact"
  ></v-pagination>
</template>

<script setup>
import EditableField from '@/components/Field/EditableField/index.vue';
</script>

<script>
import { defaultTableBodyMixin } from '@/components/Tables/DefaultTable/components/edit/components/Body';
import logMixin from '@/mixins/log';

export default {
  name: 'DefaultTableChaptersBody',
  mixins: [defaultTableBodyMixin, logMixin],
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    displayedItems: {
      type: Array,
      default: () => ([]),
    },
    payloadFields: {
      type: Array,
      default: () => ([]),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    chapterFields: {
      type: Object,
      required: true,
    },
    chaptersLoaded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getChapterValues(item) {
      const obj = {};
      Object.entries(this.chapterFields).forEach(([key]) => {
        obj[key] = item[key];
      });
      return obj;
    },
    isEqualToChapterValues(item) {
      let isEqual = true;
      Object.entries(this.getChapterValues(item)).forEach(([key, val]) => {
        if (item[key] !== val) {
          isEqual = false;
        }
      });
      return isEqual;
    },
  },
};
</script>

<style lang="sass" scoped>
.chapters_item
  display: grid
  grid-template-columns: 1fr 20px
  gap: 10px
</style>