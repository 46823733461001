<template>
  <div
    class="edit"
    :style="inline ? 'overflow-y: auto' : ''"
  >
    <v-card
      v-if="Object.keys(fields).length > 0"
      elevation="0"
      rounded="0"
      class="content pa-2"
      width="100%"
    >
      <div class="title">
        <div class="d-flex align-center">
          <v-btn
            v-if="!inline"
            icon="mdi-chevron-left"
            variant="plain"
            @click="goBack"
          />
          <slot
            name="title"
            :all-chapters="allChapters"
            :module-translation="moduleTransaltion"
          >
            <h5 class="title text-start my-4">
              {{ moduleTransaltion[storeModule] || allChapters[storeModule]?.label || storeModule }}
              <template v-if="!isCopy">
                -- создать
              </template>
              <template v-else>
                -- копировать
              </template>
            </h5>
          </slot>
        </div>
        <v-spacer />
        <slot
          name="buttons"
          :save="save"
          :loading="saveLoading"
          :disabled="fieldsLoading"
        />
        <v-btn
          v-if="isSaved"
          color="info"
          size="small"
          variant="tonal"
          @click="toShow"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <slot name="copyBtn">
          <v-btn
            v-if="showCopyBtn && actions?.values?.copy && isSaved"
            color="primary"
            size="small"
            variant="tonal"
            @click="toCopy"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </slot>
        <slot
          name="createBtnPrepend"
          :save="save"
          :loading="saveLoading"
          :disabled="fieldsLoading"
        />

        <SaveBtn
          :button-fields="actions.buttons"
          :save-loading="saveLoading"
          :fields-loading="fieldsLoading"
          @save="save"
        >
          <template
            v-if="$slots.createBtn"
            #createBtn="{loading, disabled}"
          >
            <slot
              name="createBtn"
              :loading="loading"
              :disabled="disabled"
              :save="save"
            />
          </template>
        </SaveBtn>
      </div>
      <EntityChapters
        class="mt-2"
        :disabled="fieldsLoading"
        @tabSelect="onTabSelect"
      />
      <slot name="top"></slot>
      <slot
        v-if="isDataLoaded"
        name="table"
      >
        <v-data-table
          :items="items"
          :headers="filteredColumns"
          :loading="isAjax"
          :items-per-page="100"
          class="editTable"
          :style="{width: '100%', border: `none`}"
          color="primary"
        >
          <template #headers />
          <template #bottom>
            <slot
              name="bottom"
              :fields="fields"
            />
          </template>
          <template #item.title="{value}">
            <titleCellRenderer
              :value="value"
              :fields="fields"
            />
          </template>


          <template #item.value="{item, value}">
            <value-cell-renderer
              :value="value"
              :item="item"
              :fields="fields"
              @setWatchQuery="setWatchQuery"
              @setWatchQuerySearch="setWatchQuerySearch"
            >
              <template
                v-if="$slots[`item.${item.title.fieldName}`]"
                v-slot:[`item.${item.title.fieldName}`]
              >
                <slot
                  :name="`item.${item.title.fieldName}`"
                  :value="value"
                  :item="item"
                  :field-name="item.title.fieldName"
                />
              </template>
            </value-cell-renderer>
          </template>
          <template #loading>
            <v-skeleton-loader
              type="table-tfoot@4"
              class="pa-2"
            />
          </template>
        </v-data-table>
      </slot>
      <v-skeleton-loader
        v-else
        type="table-tfoot@4"
        class="pa-2"
      />
    </v-card>
    <template v-if="!inline">
      <v-navigation-drawer
        v-model="asideIsOpen"
        location="right"
        temporary
        :width="asideWidth"
        @close="closeAside"
      >
        <slot name="asideContent">
          <ExcelLoad
            v-if="asideName === 'excel'"
            :sample="asideData.excel_sample"
            :post-sample="asideData.excel_sample_post || asideData.excel_sample"
            @setPayload="setPayloadFromExcel"
          />
        </slot>
      </v-navigation-drawer>
    </template>
  </div>
</template>

<script>
import createRole from '@/components/RolePicker/index.vue';
import ExcelLoad from '@/components/ExcelLoad/index.vue';
import EntityChapters from '@/components/EntityChapters/index.vue';
import valueCellRenderer from '@/components/CellRenderers/EditValue/index.vue';
import SaveBtn from '@/components/UiKit/SaveBtn/index.vue';

import { fileMixin } from '@/mixins/fileMixin';
import { payloadMixin } from '@/mixins/payload';
import { editMixin } from '@/mixins/edit';

export default {
  name: 'Create',
  components: {
    valueCellRenderer,
    createRole,
    ExcelLoad,
    EntityChapters,
    SaveBtn,
  },
  mixins: [fileMixin, payloadMixin, editMixin],
  provide() {
    return {
      isCreate: true,
    };
  },
  emits: ['save', 'generatePayload'],
  data() {
    return {
      isSaved: false,
      watchQuery: { },
    };
  },
  computed: {
    items() {
      return Object.entries(this.fields)
        .filter((el) => !this.hiddenFields.includes(el[0]) && el[1].label)
        .map((el) => {
          // eslint-disable-next-line no-shadow
          const title = { label: this.fields[el[0]]?.label, fieldName: el[0] } || el[0];
          if (el[1].types && !Object.values(el[1].types).includes(8)) {
            return { title, value: null };
          }
          return { title, value: { values: [] } };
        });
    },
    moduleType() {
      return this.isSaved ? 'edit' : 'create';
    },
  },
  watch: {
    watchQuery: {
      handler() {
        clearTimeout(this.watchQueryTimeoutId);

        this.watchQueryTimeoutId = setTimeout(() => {
          this.getFields({ allField: false })
            .then(() => {
              this.generatePayload({ oldFields: this.lastFields });
            });
        }, 100);
      },
      deep: true,
    },
  },
  beforeMount() {
    const requests = [this.$store.dispatch(`${this.storeModule}/getFields`, { organization: null, moduleType: this.moduleType, query: { ...this.fields_query, chapterType: 'main' } })];
    if (this.id && !this.inline) {
      requests.push(this.$store.dispatch(`${this.storeModule}/getShow`, { id: this.id, moduleType: 'copy' }));
    }
    Promise.all(requests)
      .then(() => {
        this.chapter = Object.values(this.tabChapters)?.[0]?.chapterType || 'main';
        this.generatePayload({ sendFields: false, isFirst: true });
        this.isDataLoaded = true;
      });
  },
  beforeUnmount() {
    this.clearPayload();
  },
  methods: {
    save(redirect = true, showAlert = true, replace = true) {
      return this.$store.dispatch(`${this.storeModule}/${this.isSaved ? 'edit' : 'create'}`, { payload: this.payload, query: this.completedQuery })
        .then((res) => {
          this.$emit('save', res.data);
          this.isSaved = true;
          this.payload.id = res.data.id.value;
          return this.saveFile(this.payload.id);
        })
        .then(() => {
          if (this.inline) return;
          if (showAlert) this.$root.toast.show({ message: 'Данные сохранены' });
          if (redirect) {
            this.goBack();
            return;
          }
          this.generatePayload({ oldFields: this.lastFields });
          this.getFields();
          if (replace)window.history.replaceState(null, document.title, `/${this.storeModule}/edit/${this.payload.id}`);
        })
        .catch(() => {
          this.$root.toast.show({ message: 'Ошбика при сохранении', icon: 'mdi-alert-circle-outline', color: 'error' });
        });
    },
    generatePayload({ sendFields = true, isFirst = false, oldFields = {} }) {
      let hasNew = false;
      if (this.isCopy && isFirst) {
        Object.entries(this.showData).forEach((el) => {
          if (el[0] === 'id') return;
          this.payload[el[0]] = this.transformObject(el[1]);
        });
      }
      Object.entries(this.fields).forEach((el) => {
        if (el[1].clear_on_change) {
          el[1].clear_on_change.forEach((key) => {
            if (this.clearOnChange[key]) {
              this.clearOnChange[key].push(el[0]);
            } else {
              this.clearOnChange[key] = [el[0]];
            }
          });
        }

        if (!this.payload[el[0]] && !this.isCopy) {
          this.payload[`${el[0]}`] = null;
          if (el[1].field_type === 'checkbox') {
            this.payload[`${el[0]}`] = 0;
          } else if (el[1].types && !Object.values(el[1].types).includes(8)) {
            this.payload[`${el[0]}`] = null;
          } else {
            this.payload[`${el[0]}`] = [];
          }
        }

        const isNew = !Object.keys(oldFields).includes(el[0]);
        // eslint-disable-next-line no-nested-ternary
        const value = this.isCopy ? !isNew ? this.payload[`${el[0]}`] : this.transformObject(this.showData[el[0]]) : this.payload[`${el[0]}`] ?? el[1]?.default_id;

        this.payload[`${el[0]}`] = structuredClone(value);

        if (el[1].request_on_change && this.watchQuery && !Object.keys(this.watchQuery).includes(`${el[0]}`)) {
          this.setupWatchers(el);
          hasNew = true;
        }
      });
      if (hasNew && sendFields) {
        this.getFields();
      }
      this.setPayload();
      this.$emit('generatePayload');
    },
    setupWatchers(el) {
      // Проверяем есть ли в текущем payload элементы, требующие запроса
      if (!this.payload[`${el[0]}`]) {
        this.watchQuery[el[0]] = {};
      } else {
        const obj = {};
        obj[el[0]] = `[${this.payload[`${el[0]}`]}]`;
        this.watchQuery = { ...this.watchQuery, ...obj };
      }

      this.$watch(() => this.payload[`${el[0]}`], function (newVal, oldVal) {
        if (this.clearOnChange[el[0]]?.length) {
          this.clearOnChange[el[0]].forEach((key) => {
            delete this.payload[key];
          });
        }
        if (newVal === undefined) {
          this.watchQuery[el[0]] = {};
          return;
        }
        if (newVal !== oldVal) {
          const obj = {};
          obj[el[0]] = `[${newVal}]`;
          this.watchQuery = { ...this.watchQuery, ...obj };
        }
      });
    },
    setPayloadFromExcel(data, parent = null, innerIndex) {
      if (parent && innerIndex !== undefined) {
        Object.entries(data).forEach((d) => {
          this.payload[parent][innerIndex][d[0]] = d[1];
        });
        return;
      } if (parent) {
        Object.entries(data).forEach((d) => {
          this.payload[parent][d[0]] = d[1];
        });
        return;
      }
      Object.entries(data).forEach((d) => {
        this.payload[d[0]] = d[1];
      });
    },
    onTabSelect(tab) {
      this.abort();
      this.chapter = tab;
      this.query.chapterType = tab;
      this.$emit('update:chapter', tab);

      this.getFields(!this.openedChapters.includes(tab), !this.openedChapters.includes(tab));
      this.openedChapters.push(tab);
    },
    toCopy() {
      window.open(`${this.$route.path.startsWith('/profile') ? '/profile' : ''}/${this.storeModule}/create?copyFrom=${this.payload.id}`, '_blank');
    },
    toShow() {
      this.$router.push(`${this.isProfile}/${this.storeModule}/show/${this.payload.id}?routeFrom=edit`);
    },
  },
};
</script>
